import { Fragment, useState, useEffect, ChangeEvent, useRef } from "react";

import CustomInputField from "../../CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../CustomHTMLElements/CustomSelectDropdown";
import { useHistory } from "react-router-dom";
import Loader from "../../Loader/Loader.component";
import { errorHandler } from "../../../helpers/errorHandler";
import CustomTextArea from "../../CustomHTMLElements/CustomTextArea";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postDataWithDotNet } from "../../../apis/dotNetApiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import useAggregatorProducts from "../../../custom-hooks/useAggregatorProducts";
import PageLoader from "../../PageLoader/PageLoader.component";
import moment from "moment";
import { loanRequestSchema } from "../../../helpers/loanRequestSchema";
import useBusinessSectors from "../../../custom-hooks/useBusinessSectors";
import useCountryBanksList from "../../../custom-hooks/useCountryBanksList";
import { useAuth } from "../../../Context/auth.context";
import useCountryStates from "../../../custom-hooks/useCountryStates";
import useValidateBankAccount from "../../../custom-hooks/useValidateBankAccount";
import LoaderColored from "../../Loader/LoaderColored";
import BackButtonComponent from "../../BackButton/BackButton.component";
import styles from "../MakeLoanRequest.module.scss";
import LoanOfferModalIndividualRequest from "../LoanOfferModal/LoanOfferModalIndividualRequest";
import useSearchForBorrower from "../../../custom-hooks/useSearchForBorrower";
import useSearchBorrowerDetails from "../../../custom-hooks/useSearchBorrowerDetails";
import { appInsights } from "../../AppInsight/AppInsight";
import { handleFormatAmountNoCurrency } from "../../../helpers/formatterNoDecimal";
import useAdvanclyCurrentCountries from "../../../custom-hooks/useAdvanclyCurrentCountries";

const schema = yup.object().shape({
  firstName: loanRequestSchema.firstName,
  lastName: loanRequestSchema.lastName,
  email: loanRequestSchema.email,
  address: loanRequestSchema.address,
  idNumber: loanRequestSchema.idNumber,
  bankName: loanRequestSchema.bankName,
  accountNumber: loanRequestSchema.accountNumber,
  accountName: loanRequestSchema.accountName,
  borrowerPhoneNumber: loanRequestSchema.borrowerPhoneNumber,
  borrowerCity: loanRequestSchema.borrowerCity,
  productId: loanRequestSchema.productId,
  loanAmount: loanRequestSchema.loanAmount,
  loanTenure: loanRequestSchema.loanTenure,
  // loanReferenceNumber: loanRequestSchema.loanReferenceNumber,
  borrowerSector: loanRequestSchema.borrowerSector,
  // repaymentStartDate: loanRequestSchema.repaymentStartDate,
  interestRate: loanRequestSchema.interestRate,
  borrowerGender: loanRequestSchema.borrowerGender,
  dateOfBirth: loanRequestSchema.dateOfBirth,
  loanPurpose: loanRequestSchema.loanPurpose,
  country: loanRequestSchema.country,
  state: loanRequestSchema.state,
});

export default function IndividualSingleLoanRequest() {
  const {
    aggregatorDetails,
    setBorrowerSearchDetailsCorporate,
    borrowerSearchDetailsIndividual,
  } = useAuth();

  const [error, setError] = useState(false);
  const [success, setSucess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [productId, setProductId] = useState<any>(null);
  const [loanTenure, setLoanTenure] = useState<any>(null);
  const [loanTenureMax, setLoanTenureMax] = useState<any>(null);
  // const [interestRate, setInterestRate] = useState(null);
  const [filteredSearch, setFilteredSearch] = useState<any>(null);
  const [borrowerSearchId, setBorrowerSearchId] = useState(null);
  const [requestBody, setRequestBody] = useState<any>(null);
  const [productName, setProductName] = useState(null);
  const [borrowerType, setBorrowerType] = useState(null);
  const [countrycode, setCountryCode] = useState<any>(null);
  const [amountValue, setAmountValue] = useState("");

  // Loan offer modal state
  const [loanOfferError, setLoanOfferError] = useState<string | null>(null);
  const [loanOfferSuccess, setLoanOfferSucess] = useState(false);
  const [loanOfferLoading, setLoanOfferLoading] = useState(false);
  const [loanTenureInDays, setLoanTenureInDays] = useState();
  const [loanCurrency, setLoanCurrency] = useState();
  const [totalPrincipal, setTotalPrincipal] = useState();
  const [totalRepayment, setTotalRepayment] = useState();
  const [totalInterestCharged, setTotalInterestCharged] = useState();
  const triggerLoanOfferModal = useRef<any>();
  const scrollToTop = () => {
    //@ts-ignore
    document.getElementById("topOfPage").scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };
  const {
    data: countries,
    isLoading: isLoadingCountries,
    error: countriesError,
  } = useAdvanclyCurrentCountries();
  const history = useHistory();
  const { register, handleSubmit, errors, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema),
  });
  const country = watch("country");

  const countryHandler = (e: ChangeEvent<HTMLInputElement>) =>
    setCountryCode(e.target.value);

  const resolveCountryInfo = countries?.find(
    ({ iso2 }: { iso2: string }) => iso2 === (country || countrycode)
  );
  const { data: states, isLoading: isStatesLoading } = useCountryStates({
    countryISO: resolveCountryInfo?.iso2,
  });
  const {
    data: bankListData,
    status: bankStatus,
    error: bankListError,
  } = useCountryBanksList({
    country_code: resolveCountryInfo?.iso2 || countrycode,
  });

  let now = moment();

  const {
    data: productsData,
    status: productsStatus,
    error: productsError,
  } = useAggregatorProducts({
    country_code: countrycode
      ? countrycode
      : country !== 0 || country !== undefined
      ? country
      : "",
  });

  const {
    data: sectorsData,
    status: sectorsStatus,
    error: sectorsError,
  } = useBusinessSectors();
  useEffect(() => {
    if (productId) {
      const product = productsData.find(
        (eachProduct: { id: number }) =>
          Number(eachProduct.id) === Number(productId) && eachProduct
      );
      setValue("loanTenure", product.maximum_tenor);
      setLoanTenureMax(product.maximum_tenor);
      // setInterestRate(product?.interest_rate);
      setValue("interestRate", product?.interest_rate);
      setProductName(product.product_name);
    }
  }, [productId, productsData, setValue]);

  const productHandlerId = (e: ChangeEvent<HTMLInputElement>) => {
    setProductId(e.target.value);
  };
  const accountNumber = watch("accountNumber");
  const bankCodeValue = watch("bankName");
  const bankCode = bankListData?.find((bank: { code: string }) => {
    return bank?.code === bankCodeValue;
  })?.code;

  useEffect(() => {
    setValue("accountNumber", "");

    setValue("accountName", "");
    // eslint-disable-next-line
  }, [bankCodeValue]);
  const {
    data: accountName,
    isLoading: isAccountNameLoading,
    error: accountNameError,
  } = useValidateBankAccount({
    bankCode,
    accountNumber,
  });

  useEffect(() => {
    setValue("accountName", accountName);
    // eslint-disable-next-line
  }, [accountName]);
  const {
    data: BorrowerSearchData,
    status: BorrowerSearchStatus,
    error: BorrowerSearchError,
  } = useSearchForBorrower({
    search_keywords: filteredSearch,
  });

  const {
    data: BorrowerSearchDataDetails,
    status: BorroweSearchDataDetailsStatus,
    error: BorrowerSearchDataDetailsError,
  } = useSearchBorrowerDetails({
    borrowerId: borrowerSearchId,
  });

  useEffect(() => {
    if (BorrowerSearchDataDetails) {
      const {
        first_name,
        last_name,
        gender,
        email,
        phone_number,
        // phone_number_dial_code,
        residence_address,
        date_of_birth_formatted,
        identity_number,
        country,
        state,
        city,
        bank_account_name,
        bank_account_num,
        bank_code,
      } = BorrowerSearchDataDetails;

      if (borrowerType === "Corporate") {
        setBorrowerSearchDetailsCorporate(BorrowerSearchDataDetails);
        history.push("/corporate-loan-request");
      } else {
        setBorrowerSearchDetailsCorporate();
        setValue("firstName", first_name);
        setValue("lastName", last_name);
        setValue("email", email);
        setValue("borrowerGender", gender);
        setValue("borrowerPhoneNumber", phone_number);
        setValue("address", residence_address);
        setValue("idNumber", identity_number);
        setValue("dateOfBirth", date_of_birth_formatted);
        setValue("country", country);
        setTimeout(() => {
          setValue("state", state);
        }, 2000);
        setValue("borrowerCity", city);

        if (BorrowerSearchDataDetails?.bank_code) {
          setTimeout(() => {
            setValue("bankName", bank_code);
          }, 2000);
        }
        if (BorrowerSearchDataDetails?.bank_code) {
          setTimeout(() => {
            setValue("accountNumber", bank_account_num);
          }, 4000);
        }
        if (BorrowerSearchDataDetails?.bank_code) {
          setTimeout(() => {
            setValue("accountName", bank_account_name);
          }, 5000);
        }
      }
    }
  }, [
    BorrowerSearchDataDetails,
    setValue,
    borrowerType,
    history,
    setBorrowerSearchDetailsCorporate,
  ]);

  // This effect would only work for when the user is being routed to corporate from individual
  //The goal is if user searches for corporate in individual route to corporate likewise same for corporate
  useEffect(() => {
    if (borrowerSearchDetailsIndividual) {
      const {
        first_name,
        last_name,
        gender,
        email,
        phone_number,
        // phone_number_dial_code,
        residence_address,
        date_of_birth_formatted,
        identity_number,
        country,
        state,
        city,
        bank_account_name,
        bank_account_num,
        bank_code,
      } = borrowerSearchDetailsIndividual;
      setValue("firstName", first_name);
      setValue("lastName", last_name);
      setValue("email", email);
      setValue("borrowerGender", gender);
      setValue("borrowerPhoneNumber", phone_number);
      setValue("address", residence_address);
      setValue("idNumber", identity_number);
      setValue("dateOfBirth", date_of_birth_formatted);
      setValue("country", country);
      setTimeout(() => {
        setValue("state", state);
      }, 3000);
      setValue("borrowerCity", city);

      if (borrowerSearchDetailsIndividual?.bank_code) {
        setTimeout(() => {
          setValue("bankName", bank_code);
        }, 2000);
      }
      if (borrowerSearchDetailsIndividual?.bank_code) {
        setTimeout(() => {
          setValue("accountNumber", bank_account_num);
        }, 3000);
      }
      if (borrowerSearchDetailsIndividual?.bankCode) {
        setTimeout(() => {
          setValue("accountName", bank_account_name);
        }, 4000);
      }
    }
  }, [borrowerSearchDetailsIndividual, setValue]);
  const resetAmountValue = () => {
    setAmountValue("");
  };

  const onSubmit = async (data: any) => {
    const {
      firstName,
      lastName,
      email,
      address,
      idNumber,
      accountNumber,
      accountName,
      borrowerGender,
      borrowerPhoneNumber,
      borrowerCity,
      productId,
      loanAmount,
      loanTenure,
      // loanReferenceNumber,
      borrowerSector,
      // repaymentStartDate,
      interestRate,
      bankName,
      dateOfBirth,
      loanPurpose,
      state,
      country,
    } = data;

    const reqBody = {
      product_id: Number(productId),
      loan_amount: +handleFormatAmountNoCurrency(String(loanAmount)),
      loan_tenure: Number(loanTenure),
      sector_code: borrowerSector,
      // repayment_start_date: moment(repaymentStartDate).format("DD/MM/YYYY"),
      annual_interest_rate: Number(interestRate),
      bvn_checker: true,
      bank_account_checker: true,
      borrower_metadata: "",
      // aggregator_loan_ref: loanReferenceNumber,
      email: email,
      first_name: firstName,
      last_name: lastName,
      bank_account_num: accountNumber,
      bank_account_name: accountName,
      bank_code: bankName,
      date_of_birth: dateOfBirth,
      photo_url: "",
      borrower_type: 1,
      aggregator_id: Number(aggregatorDetails.aggregator_id),
      gender: borrowerGender,
      residence_address: address,
      city: borrowerCity,
      phone_number: borrowerPhoneNumber,
      identity_number: idNumber,
      loan_purpose: loanPurpose,
      state,
      country_code: country,
    };
    setRequestBody(reqBody);
    //Post for Loan Schedule
    const reqBodyForLoanSchedule = {
      productId: Number(productId),
      loanTenor: Number(loanTenure),
      principalAmount: +handleFormatAmountNoCurrency(String(loanAmount)),
      interest: Number(interestRate),
      loanEffectiveDate: now.format(),
    };
    setLoanOfferLoading(true);
    setLoanOfferError(null);
    setLoanOfferSucess(false);

    try {
      const data = await postDataWithDotNet(
        apiEndpoints.LOAN_OFFER_SCHEDULE,
        reqBodyForLoanSchedule
      );

      setLoanOfferLoading(false);
      if (data.status === true) {
        setLoanOfferSucess(data.message);
        setTimeout(() => {
          setLoanOfferSucess(false);
        }, 5000);
        setLoanTenureInDays(data?.data?.loanTermInDays);
        setLoanCurrency(data?.data?.currency?.code);
        setTotalPrincipal(data?.data?.totalPrincipalExpected);
        setTotalRepayment(data?.data?.totalRepaymentExpected);
        setTotalInterestCharged(data?.data?.totalInterestCharged);
        triggerLoanOfferModal.current.click();
      } else {
        setLoanOfferError(data.message);
        setTimeout(() => {
          setLoanOfferError(null);
        }, 7000);
      }
    } catch (error: any) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "IndividualSingleLoanRequest.component.tsx" },
      });
      setLoanOfferLoading(false);
      if (error?.response?.status === 401) {
        sessionStorage.removeItem("token");
        history.push("/login");
      } else {
        // setLoanOfferError(errorHandler(error));
        setLoanOfferError(errorHandler(error?.response?.data?.message));

        scrollToTop();
      }
    }
    //loan offer is displayed first
  };

  return (
    <Fragment>
      <div id="topOfPage">
        <div className={styles.rootMainRequest}>
          {loanOfferSuccess && (
            <p className="alert alert-success small">{loanOfferSuccess}</p>
          )}
          {loanOfferError && (
            <p className="alert alert-danger small">{loanOfferError}</p>
          )}
          {bankListError && (
            <p className="alert alert-danger small">
              {errorHandler(bankListError)}
            </p>
          )}
          {accountNameError && (
            <p className="alert alert-danger mb-4 small ">
              {errorHandler(accountNameError)}
            </p>
          )}
          {productsError && (
            <p className="alert alert-danger small">
              {errorHandler(productsError)}
            </p>
          )}
          {sectorsError && (
            <p className="alert alert-danger small">
              {errorHandler(sectorsError)}
            </p>
          )}
          {BorrowerSearchDataDetailsError && (
            <p className="alert alert-danger small">
              {errorHandler(BorrowerSearchDataDetailsError)}
            </p>
          )}
          {countriesError && (
            <p className="alert alert-danger small">
              {errorHandler(countriesError)}
            </p>
          )}
          {BorrowerSearchError && (
            <p className="alert alert-danger small">
              {errorHandler(BorrowerSearchError)}
            </p>
          )}
        </div>
        <div className="mb-2">
          <div className="row  align-items-center justify-content-center">
            <div className="col-12 col-lg-6 mb-0">
              <input
                className={`form-control ${styles.searchInput}`}
                name="searchBorrower"
                type="search"
                maxLength={30}
                ref={register}
                placeholder="Search for an existing borrower"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFilteredSearch(e.target.value);
                }}
                style={{
                  borderRadius: "10px ",
                }}
              />
            </div>
          </div>
          {filteredSearch && filteredSearch.length >= 3 && (
            <div className="row  align-items-center justify-content-center">
              <div className={`${styles.borrowerSearchResult} col-12 col-lg-6`}>
                {BorrowerSearchStatus === "loading" ? (
                  <PageLoader />
                ) : BorrowerSearchData?.length === 0 ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="p-1">Not found</p>
                  </div>
                ) : (
                  BorrowerSearchData &&
                  BorrowerSearchData?.map((searchResults: any) => {
                    const {
                      id,
                      first_name,
                      last_name,
                      user_id,
                      identity_number,
                      borrower_type,
                    } = searchResults;
                    return (
                      <div
                        key={id}
                        className={`d-flex justify-content-between align-items-center p-2 ${styles.borrowerSearchResultResponse}`}
                        onClick={() => {
                          setBorrowerSearchId(user_id);
                          setBorrowerType(borrower_type);
                          setTimeout(() => {
                            setValue("searchBorrower", "");
                            setFilteredSearch(null);
                          }, 1000);
                        }}
                      >
                        <span>{`${first_name} ${last_name}`}</span>
                        <span> {`${borrower_type} Borrower`} </span>
                        <div>
                          <span>{identity_number}</span>

                          {BorroweSearchDataDetailsStatus === "loading" && (
                            <LoaderColored />
                          )}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
          <h3 className="page-title d-flex align-items-center">
            <BackButtonComponent /> Loan Request
          </h3>
        </div>
        <p className="text-left">
          Fill out the form below to request a loan for a new Individual
          borrower.{" "}
        </p>
        <p className="text-danger small text-left mb-4">
          Fields with asterisks (*) are required
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h5 className="page-subtitle text-left my-3 mb-3">
            Personal Information
          </h5>
          <div className="row">
            {/* Beginning of country list */}

            <div className="col-12 col-lg-6">
              <CustomSelectDropdown
                ref={register}
                label="Country"
                name="country"
                className={
                  borrowerSearchId || borrowerSearchDetailsIndividual
                    ? `${styles.disableCursor}`
                    : ""
                }
                errors={errors.country}
                showRequiredIcon={true}
                extraLabel={isLoadingCountries ? "(Loading...)" : null}
                // readOnly={borrowerSearchId || borrowerSearchDetailsIndividual}
                onChange={countryHandler}
              >
                <option value="">Country</option>
                {countries?.map(({ name, id, iso2 }: any) => {
                  return (
                    <option value={iso2} key={id}>
                      {name}
                    </option>
                  );
                })}
              </CustomSelectDropdown>
            </div>

            <div className="col-12 col-lg-6">
              <CustomInputField
                type="number"
                maxLength={20}
                ref={register}
                placeholder={
                  country === "NG"
                    ? "Enter BVN Number"
                    : "Enter National Identity Number"
                }
                label={
                  country === "NG" ? "BVN Number" : "National Identity Number"
                }
                name="idNumber"
                errors={errors.idNumber}
                showRequiredIcon={true}
                extraLabel={
                  country === "NG"
                    ? "(e.g. BVN)"
                    : "(e.g. National identity number)"
                }
                readOnly={borrowerSearchId || borrowerSearchDetailsIndividual}
              />
            </div>

            <div className="col-12 col-lg-6">
              <CustomInputField
                type="text"
                maxLength={30}
                ref={register}
                placeholder="Enter borrower's first name"
                label="First Name"
                name="firstName"
                errors={errors.firstName}
                showRequiredIcon={true}
                readOnly={borrowerSearchId || borrowerSearchDetailsIndividual}
              />
            </div>
            <div className="col-12 col-lg-6">
              <CustomInputField
                type="text"
                maxLength={30}
                ref={register}
                placeholder="Enter borrower's last name"
                label="Last Name"
                name="lastName"
                errors={errors.lastName}
                showRequiredIcon={true}
                readOnly={borrowerSearchId || borrowerSearchDetailsIndividual}
              />
            </div>
            <div className="col-12 col-lg-6">
              <CustomInputField
                type="email"
                maxLength={128}
                ref={register}
                placeholder="Enter borrower's email address"
                label="Email"
                name="email"
                errors={errors.email}
                showRequiredIcon={true}
                readOnly={borrowerSearchId || borrowerSearchDetailsIndividual}
              />
            </div>
            <div className="col-12 col-lg-6">
              <CustomInputField
                type="number"
                maxLength={20}
                ref={register}
                label="Phone Number"
                placeholder="Enter borrower's Phone Number"
                name="borrowerPhoneNumber"
                errors={errors.borrowerPhoneNumber}
                showRequiredIcon={true}
                readOnly={borrowerSearchId || borrowerSearchDetailsIndividual}
              />
            </div>
            <div className="col-12 col-lg-6">
              <CustomSelectDropdown
                ref={register}
                label="Gender"
                name="borrowerGender"
                className={
                  borrowerSearchId || borrowerSearchDetailsIndividual
                    ? `${styles.disableCursor}`
                    : ""
                }
                errors={errors.borrowerGender}
                showRequiredIcon={true}
                // readOnly={borrowerSearchId || borrowerSearchDetailsIndividual}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </CustomSelectDropdown>
            </div>

            <div className="col-12 col-lg-6">
              <CustomInputField
                type="date"
                ref={register}
                label="Date of birth"
                placeholder="Enter borrower's date of birth"
                name="dateOfBirth"
                errors={errors.dateOfBirth}
                // max={moment().subtract(15, "years").format().substring(0, 10)}
                showRequiredIcon={true}
                readOnly={borrowerSearchId || borrowerSearchDetailsIndividual}
              />
            </div>

            <div className="col-12 col-lg-12">
              <CustomTextArea
                maxLength={256}
                ref={register}
                placeholder="Enter borrower's address"
                label="Address"
                name="address"
                errors={errors.address}
                showRequiredIcon={true}
                readOnly={borrowerSearchId || borrowerSearchDetailsIndividual}
              />
            </div>

            <div className="col-12 col-lg-6">
              <CustomSelectDropdown
                ref={register}
                label="State"
                name="state"
                errors={errors.state}
                showRequiredIcon={true}
                extraLabel={isStatesLoading ? "(Loading...)" : ""}
                className={
                  borrowerSearchId || borrowerSearchDetailsIndividual
                    ? `${styles.disableCursor}`
                    : ""
                }
                // readOnly={borrowerSearchId || borrowerSearchDetailsIndividual}
              >
                <option value="">State</option>;
                {states?.map(({ name, id }: any) => {
                  return (
                    <option value={name} key={id}>
                      {name}
                    </option>
                  );
                })}
              </CustomSelectDropdown>
            </div>

            <div className="col-12 col-lg-6">
              <CustomInputField
                type="text"
                maxLength={128}
                ref={register}
                label="City"
                placeholder="Enter borrower's city"
                name="borrowerCity"
                errors={errors.borrowerCity}
                showRequiredIcon={true}
                readOnly={borrowerSearchId || borrowerSearchDetailsIndividual}
              />
            </div>

            {/* end of country and state */}
          </div>
          <div></div>
          <h5 className="page-subtitle text-left my-3 mb-3">
            Bank Information
          </h5>

          <div className="row">
            <div className="col-12 col-lg-6">
              <CustomSelectDropdown
                ref={register}
                label="Bank"
                name="bankName"
                className={
                  (BorrowerSearchDataDetails?.bank_code &&
                    BorrowerSearchDataDetails?.bank_name) ||
                  (borrowerSearchDetailsIndividual?.bank_code &&
                    borrowerSearchDetailsIndividual?.bank_name)
                    ? `${styles.disableCursor}`
                    : `${styles.activeCursor}`
                }
                errors={errors.bankName}
                showRequiredIcon={true}
                // readOnly={borrowerSearchId || borrowerSearchDetailsIndividual}
                // readOnly={
                //   (BorrowerSearchDataDetails?.bank_code &&
                //     BorrowerSearchDataDetails?.bank_name) ||
                //   (borrowerSearchDetailsIndividual?.bank_code &&
                //     borrowerSearchDetailsIndividual?.bank_name)
                // }
              >
                <option value="">Select Borrower's Bank</option>

                {bankListData &&
                  bankListData?.length &&
                  bankListData?.map((bank: any) => (
                    <option key={bank.code} value={bank.code}>
                      {bank.name}
                    </option>
                  ))}
              </CustomSelectDropdown>
              {bankStatus === "loading" ? (
                <div className=" d-flex justify-content-end">
                  {" "}
                  <LoaderColored />{" "}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-12 col-lg-6">
              <CustomInputField
                type="number"
                maxLength={20}
                ref={register}
                placeholder="Enter borrower's Account Number"
                label="Account Number"
                name="accountNumber"
                errors={errors.accountNumber}
                showRequiredIcon={true}
                readOnly={
                  (BorrowerSearchDataDetails?.bank_account_num &&
                    BorrowerSearchDataDetails?.bank_code) ||
                  (borrowerSearchDetailsIndividual?.bank_account_num &&
                    borrowerSearchDetailsIndividual?.bank_code)
                }
              />
            </div>
            {country === "NG" && (
              <div className="col-12 col-lg-6">
                <CustomInputField
                  type="text"
                  maxLength={128}
                  placeholder=" Account Name"
                  label="Account Name"
                  name="accountName"
                  errors={errors.accountName}
                  showRequiredIcon={true}
                  readOnly
                  defaultValue={accountName}
                  // value={accountName || ""}
                  ref={register}
                />
                {isAccountNameLoading ? (
                  <div className=" d-flex justify-content-end">
                    {" "}
                    <LoaderColored />{" "}
                  </div>
                ) : (
                  ""
                )}
                {accountNameError && (
                  <span className="text-danger  small ">
                    {errorHandler(accountNameError)}
                  </span>
                )}
              </div>
            )}
            {country !== "NG" && (
              <div className="col-12 col-lg-6">
                <CustomInputField
                  type="text"
                  maxLength={128}
                  ref={register}
                  placeholder="Enter  Account Name"
                  label="Account Name"
                  name="accountName"
                  errors={errors.accountName}
                  showRequiredIcon={true}
                />
              </div>
            )}
          </div>
          <div></div>
          <h5 className="page-subtitle text-left my-3">Loan Information</h5>

          <div className="row">
            <div className="col-12 col-lg-6">
              <CustomSelectDropdown
                ref={register}
                label="Loan Product"
                name="productId"
                errors={errors.productId}
                showRequiredIcon={true}
                onChange={productHandlerId}
              >
                <option value="">Select Product</option>
                {productsData &&
                  productsData.length &&
                  productsData.map((product: any) => (
                    <option key={product.id} value={product.id}>
                      {product.product_name}
                    </option>
                  ))}
              </CustomSelectDropdown>
              {productsStatus === "loading" ? (
                <div className=" d-flex justify-content-end">
                  {" "}
                  <LoaderColored />{" "}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-12 col-lg-6">
              <CustomInputField
                type="number"
                ref={register}
                label="Loan Amount"
                value={amountValue}
                placeholder="Enter loan amount"
                name="loanAmount"
                errors={errors.loanAmount}
                showRequiredIcon={true}
                enableSeparator
                onChange={(values: any) => {
                  const { formattedValue } = values;
                  setAmountValue(formattedValue);
                }}
              />
            </div>
            <div className="col-12 col-lg-6">
              <CustomInputField
                type="number"
                maxLength={3}
                ref={register}
                label="Loan Tenure"
                name="loanTenure"
                min={10}
                max={loanTenureMax}
                placeholder="Enter Loan Tenure"
                errors={errors.loanTenure}
                extraLabel="(in days)"
                showRequiredIcon={true}
                defaultValue={loanTenure}
                // value={loanTenure || ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setLoanTenure(e.target.value)
                }
                readOnly={!productId}
              />
            </div>
            <div className="col-12 col-lg-6">
              <CustomInputField
                type="number"
                maxLength={5}
                step="any"
                ref={register}
                label="Annual Interest Rate"
                name="interestRate"
                placeholder="Enter Annual Interest Rate"
                errors={errors.interestRate}
                extraLabel="(in %)"
                showRequiredIcon={true}
                // defaultValue={interestRate || ""}
                readOnly={productId}
              />
            </div>
            <div className="col-12 col-lg-6">
              <CustomSelectDropdown
                ref={register}
                label="Sector"
                name="borrowerSector"
                errors={errors.borrowerSector}
                showRequiredIcon={true}
              >
                <option value="">Select Borrower Sector</option>
                {sectorsData &&
                  sectorsData.length &&
                  sectorsData.map((sector: any) => {
                    const { category_id, code, category_name } = sector;
                    return (
                      <option key={category_id} value={code}>
                        {category_name}
                      </option>
                    );
                  })}
              </CustomSelectDropdown>
              {sectorsStatus === "loading" ? (
                <div className=" d-flex justify-content-end">
                  {" "}
                  <LoaderColored />{" "}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-12 col-lg-6">
              <CustomTextArea
                maxLength={128}
                ref={register}
                placeholder="Loan Purpose"
                label="Purpose for loan"
                name="loanPurpose"
                errors={errors.loanPurpose}
                showRequiredIcon={true}
              />
            </div>
          </div>

          {/* <div className="col-12 col-lg-6">
          <CustomInputField
            type="text"
            maxLength="256"
            reference={register}
            label="Aggregator Loan Reference Number"
            name="loanReferenceNumber"
            placeholder="Enter Aggregator Loan Reference Number"
            errors={errors.loanReferenceNumber}
            showRequiredIcon={true}
          />
        </div> */}

          <button
            type="button"
            data-toggle="modal"
            data-target="#loanOfferModalIndividual"
            style={{ display: "none" }}
            ref={triggerLoanOfferModal}
          />

          <div className="row">
            <div className="col-4 col-lg-4">
              <button
                type="submit"
                className="btn advancly-btn btn-sm transition-3d-hover w-100"
              >
                Send Request
                {loanOfferLoading && <Loader />}
              </button>
            </div>
          </div>
        </form>

        {
          <LoanOfferModalIndividualRequest
            loanOfferError={loanOfferError}
            loanOfferSuccess={loanOfferSuccess}
            loanOfferLoading={loanOfferLoading}
            loanTenureInDays={loanTenureInDays}
            loanCurrency={loanCurrency}
            totalPrincipal={totalPrincipal}
            totalRepayment={totalRepayment}
            totalInterestCharged={totalInterestCharged}
            success={success}
            error={error}
            loading={loading}
            setLoading={setLoading}
            setError={setError}
            setSucess={setSucess}
            requestBody={requestBody}
            setRequestBody={setRequestBody}
            reset={reset}
            scrollToTop={scrollToTop}
            productName={productName}
            setBorrowerSearchId={setBorrowerSearchId}
            resetAmountValue={resetAmountValue}
          />
        }
      </div>
    </Fragment>
  );
}
